// Importing the necessary function from the '@reduxjs/toolkit' library
const { createSlice } = require('@reduxjs/toolkit');

// Creating a Redux slice for managing product-related state
const productSlice = createSlice({
  // Name of the slice, used to identify actions and reducers
  name: 'product',

  // Initial state of the slice
  initialState: {
    products: [], // An array to store product data
  },

  // Reducers are functions that specify how the state changes in response to actions
  reducers: {
    // Reducer for setting the products
    setProducts(state, action) {
      // Set the products array in the state to the payload of the action
      state.products = action.payload;
    },
  },
});

// Exporting the actions created by the slice
export const { setProducts } = productSlice.actions;

// Exporting the reducer function to be used in the Redux store
export default productSlice.reducer;
