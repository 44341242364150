// Importing the 'cogo-toast' library for displaying toast notifications.
import cogoToast from 'cogo-toast';

/*
    This code defines a Redux slice for managing a wishlist, with reducer functions for adding,
    deleting, and clearing items from the wishlist.
    It uses the 'cogo-toast' library to display toast notifications for different actions.
*/

// Destructuring 'createSlice' function from the '@reduxjs/toolkit' module.
const { createSlice } = require('@reduxjs/toolkit');

// Creating a Redux slice named 'wishlist' using 'createSlice'.
const wishlistSlice = createSlice({
    // The name of the slice, used for generating action type strings.
    name: "wishlist",

    // The initial state of the wishlist slice, containing an array 'wishlistItems'.
    initialState: {
        wishlistItems: []
    },

    // Reducer functions that define how the state should be updated in response to actions.
    reducers: {
        // Reducer function for adding a product to the wishlist.
        addToWishlist(state, action) {
            // Checking if the product is already in the wishlist based on its ID.
            // EXPLANATION ==> _README/_Javascript_Methods/findIndex.md
            const isInWishlist = state.wishlistItems.findIndex(item => item.id === action.payload.id);

            // If the product is already in the wishlist, show an info toast notification.
            if(isInWishlist > -1){
                // cogoToast.info("Product already in wishlist", {position: "bottom-left"});
            } else {
                // If the product is not in the wishlist, add it and show a success toast notification.
                state.wishlistItems.push(action.payload);
                // cogoToast.success("Added To wishlist", {position: "bottom-left"});
            }
        },

        // Reducer function for deleting a product from the wishlist.
        deleteFromWishlist(state, action){
            // Filtering out the product with the specified ID from the wishlist.
            state.wishlistItems = state.wishlistItems.filter(item => item.id !== action.payload);

            // Showing an error toast notification indicating the product removal.
            // cogoToast.error("Removed From Wishlist", {position: "bottom-left"});
        },

        // Reducer function for deleting all products from the wishlist.
        deleteAllFromWishlist(state){
            // Clearing the wishlistItems array to remove all products from the wishlist.
            state.wishlistItems = [];
        }
    },
});

// Extracting action creators from the created wishlistSlice.
export const { addToWishlist, deleteFromWishlist, deleteAllFromWishlist } = wishlistSlice.actions;

// Exporting the reducer function generated by createSlice.
export default wishlistSlice.reducer;
