// Importing the 'cogo-toast' library for displaying toasts (notifications)
import cogoToast from 'cogo-toast';

// Importing the necessary functions from the '@reduxjs/toolkit' library
const { createSlice } = require('@reduxjs/toolkit');

// Creating a Redux slice for managing items in a comparison list
const compareSlice = createSlice({
  // Name of the slice, used to identify actions and reducers
  name: "compare",

  // Initial state of the slice
  initialState: {
    compareItems: [], // An array to store items for comparison
  },

  // Reducers are functions that specify how the state changes in response to actions
  reducers: {
    // Reducer for adding an item to the comparison list
    addToCompare(state, action) {
      // Modifying the state by adding the payload (item) to the compareItems array
      state.compareItems.push(action.payload);

      // Displaying a success toast notification when an item is added
      cogoToast.success("Added To Compare", { position: "bottom-left" });
    },

    // Reducer for deleting an item from the comparison list
    deleteFromCompare(state, action) {
      // Filtering out the item with the specified id from the compareItems array
      state.compareItems = state.compareItems.filter(item => item.id !== action.payload);

      // Displaying an error toast notification when an item is removed
      cogoToast.error("Removed From Compare", { position: "bottom-left" });
    },
  },
});

// Exporting the actions created by the slice
export const { addToCompare, deleteFromCompare } = compareSlice.actions;

// Exporting the reducer function to be used in the Redux store
export default compareSlice.reducer;
