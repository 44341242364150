// Importing React library to create and manage React components.
import React from "react";

// Importing the createRoot function from the react-dom/client module.
// createRoot is used for creating a separate root for concurrent mode in React.
import { createRoot } from 'react-dom/client';

// Importing Provider from react-redux for integrating Redux store with React.
import { Provider } from 'react-redux';

// Importing the main App component.
import App from "./App";

// Importing the Redux store instance from the store file.
import { store } from "./store/store";

// Importing PersistProvider, a custom provider for persisting state.
import PersistProvider from "./store/providers/persist-provider";

// Importing the setProducts action creator from the product-slice file.
import { setProducts } from "./store/slices/product-slice";

// Importing the product data from a JSON file.
import products from "./data/products.json";

// Importing external CSS styles and libraries for styling and animations.
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// Importing custom styles for the application.
import "./assets/scss/style.scss";

// Internationalization setup (i18n).
import "./i18n";

// Dispatching the setProducts action with the product data to initialize the Redux store.
store.dispatch(setProducts(products));

// Getting the container element with the id 'root' from the HTML.
const container = document.getElementById('root');

// Creating a root for concurrent mode using createRoot and specifying the container element.
const root = createRoot(container);

// Rendering the main application wrapped with Redux Provider and custom PersistProvider.
root.render(
    <Provider store={store}>
      <PersistProvider>
        <App />
      </PersistProvider>
    </Provider>
);
