import PropTypes from "prop-types";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "../store";

/*
    PersistProvider Component: This is a functional component that serves as a wrapper for the PersistGate component.

    It takes children as a prop, which represents the components that will be wrapped by the PersistProvider.

    PersistGate Component: This component is part of the redux-persist library.

    It delays the rendering of the application's UI until the persisted state has been retrieved and saved to the Redux store.

    loading={null}: The loading prop is set to null to avoid rendering anything while the store is being rehydrated.
    persistor={persistor}: The persistor prop is provided with the persistor object, which is created using persistStore from redux-persist.
    children Prop: The children prop is specified using PropTypes to ensure that it is a required node.

    This prop is used to pass down the components that should be rendered inside the PersistProvider.

    Export Statement: The PersistProvider component is exported as the default export of the module, making it available for use in other parts of the application.
*/
// A provider component for persisting and rehydrating the Redux store
const PersistProvider = ({ children }) => {
    return (
        // The PersistGate component from redux-persist
        <PersistGate loading={null} persistor={persistor}>
            {children}
        </PersistGate>
    );
};

// PropTypes for type-checking the props
PersistProvider.propTypes = {
    children: PropTypes.node.isRequired, // Children prop is required and should be a node
};

export default PersistProvider;
