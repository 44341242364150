// Importing the necessary function from the '@reduxjs/toolkit' library
const { createSlice } = require('@reduxjs/toolkit');

// Creating a Redux slice for managing currency-related state
const currencySlice = createSlice({
  // Name of the slice, used to identify actions and reducers
  name: "currency",

  // Initial state of the slice
  initialState: {
    currencySymbol: "€", // Default currency symbol
    currencyName: "EUR", // Default currency name
    currencyRate: 1,     // Default currency rate (1:1, as it's the default currency)
  },

  // Reducers are functions that specify how the state changes in response to actions
  reducers: {
    // Reducer for setting the currency
    setCurrency(state, action) {
      // Extracting the currency name from the action payload
      const currencyName = action.payload;

      // Handling different currency cases
      if (currencyName === "USD") {
        // If the selected currency is USD
        return state = {
          currencySymbol: "$",
          currencyRate: 1,    // Assuming USD is the base currency, so rate is 1
          currencyName,
        };
      }
      if (currencyName === "EUR") {
        // If the selected currency is EUR
        return state = {
          currencySymbol: "€",
          currencyRate: 1,
          currencyName,
        };
      }
      if (currencyName === "GBP") {
        // If the selected currency is GBP
        return state = {
          currencySymbol: "£",
          currencyRate: 1,
          currencyName,
        };
      }
    },
  },
});

// Exporting the actions created by the slice
export const { setCurrency } = currencySlice.actions;

// Exporting the reducer function to be used in the Redux store
export default currencySlice.reducer;
