// Importing necessary functions and utilities from Redux Toolkit and redux-persist
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

// Importing individual reducer slices from the project
import productReducer from './slices/product-slice';
import currencyReducer from "./slices/currency-slice";
import cartReducer from "./slices/cart-slice";
import compareReducer from "./slices/compare-slice";
import wishlistReducer from "./slices/wishlist-slice";

// Configuration for redux-persist to define how the persistence should be handled
const persistConfig = {
    key: "flone", // key used in local storage
    version: 1.1, // version of the persisted storage
    storage, // storage engine, which is Redux-persist's default storage engine (local storage)
    blacklist: ["product"] // array of reducer slices to be ignored during persistence
}

// Combining all the individual reducers into a root reducer
export const rootReducer = combineReducers({
    product: productReducer,
    currency: currencyReducer,
    cart: cartReducer,
    compare: compareReducer,
    wishlist: wishlistReducer
});

// Creating a persisted reducer using redux-persist's persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Creating the Redux store with the persisted reducer and middleware configuration
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

// Creating a persistor by persisting the store
export const persistor = persistStore(store);
